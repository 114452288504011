<script>
import UPEICalorimetry from './UPEICalorimetry';
import {
  ThermometerVariation,
  SchoolToVariation,
} from '@/tasks/components/simulations/LabCalorimetry';

export default UPEICalorimetry.extend({
  name: 'WindsorCalorimetry',
  data: () => ({
    variation: SchoolToVariation.Windsor,
    thermometerVariation: ThermometerVariation.Analog,
  }),
});
</script>
